import * as React from 'react';
import Helmet from 'react-helmet';

import favicon from '../assets/favicon.ico';

const DefaultHelmet = () => {
  return (
    <Helmet titleTemplate="%s | FAYD">
      {
        // SEO Recommendations:
        // - Keep titles under 60 chars (includes the 7 chars from the titleTemplate above, so 53 chars).
        // - Use primary keywords
        // - Consider using click-enticing modifies such as "best / top / current year."
      }
      <title>Find Your Style</title>

      {
        // Request that search engines don't index (don't display in search results)
        // and don't follow (don't crawl links on the page) by default.
        // Meant to be overridden if page should be indexed.
      }
      <meta name="robots" content="noindex, nofollow" data-testid="robots"></meta>

      {
        // SEO Recommendations:
        // - Keep descriptions under 120 chars.
        // - Use primary keywords.
        // - Accurately summarize the page's content.
      }
      <meta name="description" content="FAYD Freestyle Search empowers you to find your hair and nail styles."></meta>

      {
        // Remove keywords meta tag, since modern major search engines do not use them. Rely on title and description.
        // https://www.semrush.com/blog/meta-keywords
        // <meta name="keywords" content="fayd,freestyle search,find your style"></meta>
      }

      <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>

      <link rel="icon" href={favicon} />
    </Helmet>
  );
};

export const HelmetOverrideRobotsEnableIndexDisableFollow = () => {
  return (
    <Helmet>
      <meta name="robots" content="nofollow" data-testid="robots"></meta>
    </Helmet>
  );
};

export default DefaultHelmet;
