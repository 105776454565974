/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n    query User($userId: ID!) {\n      user(userId: $userId) {\n        data {\n          ...FragmentUser\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.UserDocument,
    "\n    query UserPublic($userId: ID!) {\n      userPublic(userId: $userId) {\n        data {\n          ...FragmentUserPublic\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.UserPublicDocument,
    "\n    query UserPublicByPreferredUsername($preferredUsername: ID!) {\n      userPublicByPreferredUsername(preferredUsername: $preferredUsername) {\n        data {\n          ...FragmentUserPublic\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.UserPublicByPreferredUsernameDocument,
    "\n    query Style($userId: ID!, $styleId: ID!) {\n      style(userId: $userId, styleId: $styleId) {\n        data {\n          ...FragmentStyle\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.StyleDocument,
    "\n    query StylePublic($userId: ID!, $styleId: ID!) {\n      stylePublic(userId: $userId, styleId: $styleId) {\n        data {\n          ...FragmentStylePublic\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.StylePublicDocument,
    "\n    query StylesByUser($userId: ID!, $input: StylesByUserInput!) {\n      stylesByUser(userId: $userId, input: $input) {\n        data {\n          ...FragmentStyle\n        }\n        exclusiveStartKey {\n          userId\n          timeRequested\n          styleId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.StylesByUserDocument,
    "\n    query StylesPublicByUser($userId: ID!, $input: StylesPublicByUserInput!) {\n      stylesPublicByUser(userId: $userId, input: $input) {\n        data {\n          ...FragmentStylePublic\n        }\n        exclusiveStartKey {\n          userId\n          timeRequested\n          styleId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.StylesPublicByUserDocument,
    "\n    query StylesByApprovalState($approvalState: ApprovalState!, $input: StylesByApprovalStateInput!) {\n      stylesByApprovalState(approvalState: $approvalState, input: $input) {\n        data {\n          ...FragmentStyle\n        }\n        exclusiveStartKey {\n          approvalState\n          timeApprovalStateUpdated\n          userId\n          styleId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.StylesByApprovalStateDocument,
    "\n    query StyleFeaturesScoringTemplates {\n      styleFeaturesScoringTemplates {\n        data\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.StyleFeaturesScoringTemplatesDocument,
    "\n    query StyleChoice($userId: ID!, $styleId: ID!) {\n      styleChoice(userId: $userId, styleId: $styleId) {\n        data {\n          ...FragmentStyleChoice\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.StyleChoiceDocument,
    "\n    query StyleChoicesByUserIdAndChoiceState(\n      $userId: ID!\n      $choiceState: ChoiceState!\n      $input: StyleChoicesByUserIdAndChoiceStateInput!\n    ) {\n      styleChoicesByUserIdAndChoiceState(userId: $userId, choiceState: $choiceState, input: $input) {\n        data {\n          ...FragmentStyleChoice\n        }\n        exclusiveStartKey {\n          userId\n          choiceState\n          timeChoiceStateUpdated\n          styleId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.StyleChoicesByUserIdAndChoiceStateDocument,
    "\n    query StyleChoicesByUserIdAndServiceTypeAndChoiceState(\n      $userId: ID!\n      $serviceType: ServiceType!\n      $choiceState: ChoiceState!\n      $input: StyleChoicesByUserIdAndServiceTypeAndChoiceStateInput!\n    ) {\n      styleChoicesByUserIdAndServiceTypeAndChoiceState(\n        userId: $userId\n        serviceType: $serviceType\n        choiceState: $choiceState\n        input: $input\n      ) {\n        data {\n          ...FragmentStyleChoice\n        }\n        exclusiveStartKey {\n          userId\n          serviceType\n          choiceState\n          timeChoiceStateUpdated\n          styleId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.StyleChoicesByUserIdAndServiceTypeAndChoiceStateDocument,
    "\n    query Recommendation($userId: ID!, $styleId: ID!) {\n      recommendation(userId: $userId, styleId: $styleId) {\n        data {\n          ...FragmentRecommendation\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.RecommendationDocument,
    "\n    query RecommendationsByUser($userId: ID!, $input: RecommendationsByUserInput!) {\n      recommendationsByUser(userId: $userId, input: $input) {\n        data {\n          ...FragmentRecommendation\n        }\n        exclusiveStartKey {\n          userId\n          timeGenerated\n          styleId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.RecommendationsByUserDocument,
    "\n    query Comment($userId: ID!, $styleId: ID!, $commentId: ID!) {\n      comment(userId: $userId, styleId: $styleId, commentId: $commentId) {\n        data {\n          ...FragmentComment\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.CommentDocument,
    "\n    query CommentPublic($userId: ID!, $styleId: ID!, $commentId: ID!) {\n      commentPublic(userId: $userId, styleId: $styleId, commentId: $commentId) {\n        data {\n          ...FragmentCommentPublic\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.CommentPublicDocument,
    "\n    query CommentsByUserAndStyle($userId: ID!, $styleId: ID!, $input: CommentsByUserAndStyleInput!) {\n      commentsByUserAndStyle(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        exclusiveStartKey {\n          userId\n          styleId\n          commentId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.CommentsByUserAndStyleDocument,
    "\n    query CommentsByApprovalStateAndStyle($approvalState: ApprovalState!, $styleId: ID!, $input: CommentsByApprovalStateAndStyleInput!) {\n      commentsByApprovalStateAndStyle(approvalState: $approvalState, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        exclusiveStartKey {\n          approvalState\n          styleId\n          timeRequested\n          userId\n          commentId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.CommentsByApprovalStateAndStyleDocument,
    "\n    query CommentsPublicByApprovalStateAndStyle(\n      $approvalState: ApprovalState!\n      $styleId: ID!\n      $input: CommentsPublicByApprovalStateAndStyleInput!\n    ) {\n      commentsPublicByApprovalStateAndStyle(approvalState: $approvalState, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentCommentPublic\n        }\n        exclusiveStartKey {\n          userId\n          styleId\n          commentId\n          approvalState\n          timeRequested\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.CommentsPublicByApprovalStateAndStyleDocument,
    "\n    query CommentsByApprovalState($approvalState: ApprovalState!, $input: CommentsByApprovalStateInput!) {\n      commentsByApprovalState(approvalState: $approvalState, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        exclusiveStartKey {\n          approvalState\n          timeApprovalStateUpdated\n          styleId\n          userId\n          commentId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.CommentsByApprovalStateDocument,
    "\n    query RootCommentsByApprovalStateAndStyle(\n      $approvalState: ApprovalState!\n      $styleId: ID!\n      $input: RootCommentsByApprovalStateAndStyleInput!\n    ) {\n      rootCommentsByApprovalStateAndStyle(approvalState: $approvalState, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        exclusiveStartKey {\n          userId\n          styleId\n          commentId\n          approvalState\n          timeRequested\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.RootCommentsByApprovalStateAndStyleDocument,
    "\n    query RootCommentsPublicByApprovalStateAndStyle(\n      $approvalState: ApprovalState!\n      $styleId: ID!\n      $input: RootCommentsPublicByApprovalStateAndStyleInput!\n    ) {\n      rootCommentsPublicByApprovalStateAndStyle(approvalState: $approvalState, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentCommentPublic\n        }\n        exclusiveStartKey {\n          userId\n          styleId\n          commentId\n          approvalState\n          timeRequested\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.RootCommentsPublicByApprovalStateAndStyleDocument,
    "\n    query ChildCommentsByApprovalStateAndStyleAndRootComment(\n      $approvalState: ApprovalState!\n      $styleId: ID!\n      $rootCommentId: ID!\n      $input: ChildCommentsByApprovalStateAndStyleAndRootCommentInput!\n    ) {\n      childCommentsByApprovalStateAndStyleAndRootComment(\n        approvalState: $approvalState\n        styleId: $styleId\n        rootCommentId: $rootCommentId\n        input: $input\n      ) {\n        data {\n          ...FragmentComment\n        }\n        exclusiveStartKey {\n          userId\n          styleId\n          commentId\n          approvalState\n          rootCommentId\n          timeRequested\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.ChildCommentsByApprovalStateAndStyleAndRootCommentDocument,
    "\n    query ChildCommentsPublicByApprovalStateAndStyleAndRootComment(\n      $approvalState: ApprovalState!\n      $styleId: ID!\n      $rootCommentId: ID!\n      $input: ChildCommentsPublicByApprovalStateAndStyleAndRootCommentInput!\n    ) {\n      childCommentsPublicByApprovalStateAndStyleAndRootComment(\n        approvalState: $approvalState\n        styleId: $styleId\n        rootCommentId: $rootCommentId\n        input: $input\n      ) {\n        data {\n          ...FragmentCommentPublic\n        }\n        exclusiveStartKey {\n          userId\n          styleId\n          commentId\n          approvalState\n          rootCommentId\n          timeRequested\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.ChildCommentsPublicByApprovalStateAndStyleAndRootCommentDocument,
    "\n    query CommentLike($styleId: ID!, $userId: ID!, $commentId: ID!) {\n      commentLike(styleId: $styleId, userId: $userId, commentId: $commentId) {\n        data {\n          ...FragmentCommentLike\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.CommentLikeDocument,
    "\n    query CommentLikesByStyleAndUser($styleId: ID!, $userId: ID!, $input: CommentLikesByStyleAndUserInput!) {\n      commentLikesByStyleAndUser(styleId: $styleId, userId: $userId, input: $input) {\n        data {\n          ...FragmentCommentLike\n        }\n        exclusiveStartKey {\n          styleId\n          userId\n          commentId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.CommentLikesByStyleAndUserDocument,
    "\n    query FeatureRequest($userId: ID!, $featureRequestId: ID!) {\n      featureRequest(userId: $userId, featureRequestId: $featureRequestId) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.FeatureRequestDocument,
    "\n    query FeatureRequestPublic($userId: ID!, $featureRequestId: ID!) {\n      featureRequestPublic(userId: $userId, featureRequestId: $featureRequestId) {\n        data {\n          ...FragmentFeatureRequestPublic\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.FeatureRequestPublicDocument,
    "\n    query FeatureRequestsByApprovalState($approvalState: ApprovalState!, $input: FeatureRequestsByApprovalStateInput!) {\n      featureRequestsByApprovalState(approvalState: $approvalState, input: $input) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        exclusiveStartKey {\n          approvalState\n          timeApprovalStateUpdated\n          userId\n          featureRequestId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.FeatureRequestsByApprovalStateDocument,
    "\n    query FeatureRequestsPublicByApprovalState(\n      $approvalState: ApprovalState!\n      $input: FeatureRequestsPublicByApprovalStateInput!\n    ) {\n      featureRequestsPublicByApprovalState(approvalState: $approvalState, input: $input) {\n        data {\n          ...FragmentFeatureRequestPublic\n        }\n        exclusiveStartKey {\n          userId\n          featureRequestId\n          approvalState\n          timeApprovalStateUpdated\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.FeatureRequestsPublicByApprovalStateDocument,
    "\n    mutation CreateUser($userId: ID!, $email: String!) {\n      createUser(userId: $userId, email: $email) {\n        data {\n          ...FragmentUser\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.CreateUserDocument,
    "\n    mutation UpdateUser($userId: ID!, $input: UpdateUserInput!) {\n      updateUser(userId: $userId, input: $input) {\n        data {\n          ...FragmentUser\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.UpdateUserDocument,
    "\n    mutation UpdateUserForReferring($referringUserId: ID!, $referredUserEmail: String!) {\n      updateUserForReferring(referringUserId: $referringUserId, referredUserEmail: $referredUserEmail) {\n        data {\n          ...FragmentUser\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.UpdateUserForReferringDocument,
    "\n    mutation UpdateUserForReferred($referredUserId: ID!, $referredUserEmail: String!, $referringUserId: ID!) {\n      updateUserForReferred(\n        referredUserId: $referredUserId\n        referredUserEmail: $referredUserEmail\n        referringUserId: $referringUserId\n      ) {\n        data {\n          ...FragmentUser\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.UpdateUserForReferredDocument,
    "\n    mutation UpdateUserPreferredUsername($userId: ID!, $currentPreferredUsername: ID!, $newPreferredUsername: ID!) {\n      updateUserPreferredUsername(\n        userId: $userId\n        currentPreferredUsername: $currentPreferredUsername\n        newPreferredUsername: $newPreferredUsername\n      ) {\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.UpdateUserPreferredUsernameDocument,
    "\n    mutation CreateStyle($userId: ID!, $input: CreateStyleInput!) {\n      createStyle(userId: $userId, input: $input) {\n        data {\n          ...FragmentStyle\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.CreateStyleDocument,
    "\n    mutation UpdateStyleAsRequested($userId: ID!, $styleId: ID!, $input: UpdateStyleAsRequestedInput!) {\n      updateStyleAsRequested(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentStyle\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.UpdateStyleAsRequestedDocument,
    "\n    mutation UpdateStyleAsApproved($userId: ID!, $styleId: ID!, $input: UpdateStyleAsApprovedInput!) {\n      updateStyleAsApproved(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentStyle\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.UpdateStyleAsApprovedDocument,
    "\n    mutation UpdateStyleAsRejected($userId: ID!, $styleId: ID!, $input: UpdateStyleAsRejectedInput!) {\n      updateStyleAsRejected(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentStyle\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.UpdateStyleAsRejectedDocument,
    "\n    mutation DeleteStyle($userId: ID!, $styleId: ID!) {\n      deleteStyle(userId: $userId, styleId: $styleId) {\n        data {\n          ...FragmentStyle\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.DeleteStyleDocument,
    "\n    mutation CreateStyleChoice($userId: ID!, $styleId: ID!, $input: CreateStyleChoiceInput!) {\n      createStyleChoice(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentStyleChoice\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.CreateStyleChoiceDocument,
    "\n    mutation UpdateStyleChoiceForChoice(\n      $userId: ID!\n      $styleId: ID!\n      $serviceType: ServiceType!\n      $choice: ChoiceState!\n      $location: LocationInput\n    ) {\n      updateStyleChoiceForChoice(\n        userId: $userId\n        styleId: $styleId\n        serviceType: $serviceType\n        choice: $choice\n        location: $location\n      ) {\n        data {\n          ...FragmentStyleChoice\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.UpdateStyleChoiceForChoiceDocument,
    "\n    mutation UpdateStyleChoiceForUndo($userId: ID!, $styleId: ID!, $serviceType: ServiceType!) {\n      updateStyleChoiceForUndo(userId: $userId, styleId: $styleId, serviceType: $serviceType) {\n        data {\n          ...FragmentStyleChoice\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.UpdateStyleChoiceForUndoDocument,
    "\n    mutation DeleteStyleChoice($userId: ID!, $styleId: ID!, $serviceType: ServiceType!) {\n      deleteStyleChoice(userId: $userId, styleId: $styleId, serviceType: $serviceType) {\n        data {\n          ...FragmentStyleChoice\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.DeleteStyleChoiceDocument,
    "\n    mutation QueueStyleChoices($userId: ID!, $input: QueueStyleChoicesInput!) {\n      queueStyleChoices(userId: $userId, input: $input) {\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.QueueStyleChoicesDocument,
    "\n    mutation CreateRecommendation($userId: ID!, $styleId: ID!, $input: CreateRecommendationInput!) {\n      createRecommendation(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentRecommendation\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.CreateRecommendationDocument,
    "\n    mutation CreateComment($userId: ID!, $styleId: ID!, $input: CreateCommentInput!) {\n      createComment(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.CreateCommentDocument,
    "\n    mutation UpdateCommentAsRequested($userId: ID!, $styleId: ID!, $commentId: ID!, $input: UpdateCommentAsRequestedInput!) {\n      updateCommentAsRequested(userId: $userId, styleId: $styleId, commentId: $commentId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.UpdateCommentAsRequestedDocument,
    "\n    mutation UpdateCommentAsApproved($userId: ID!, $styleId: ID!, $commentId: ID!, $input: UpdateCommentAsApprovedInput!) {\n      updateCommentAsApproved(userId: $userId, styleId: $styleId, commentId: $commentId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.UpdateCommentAsApprovedDocument,
    "\n    mutation UpdateCommentAsRejected($userId: ID!, $styleId: ID!, $commentId: ID!, $input: UpdateCommentAsRejectedInput!) {\n      updateCommentAsRejected(userId: $userId, styleId: $styleId, commentId: $commentId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.UpdateCommentAsRejectedDocument,
    "\n    mutation DeleteComment($userId: ID!, $styleId: ID!, $commentId: ID!, $input: DeleteCommentInput!) {\n      deleteComment(userId: $userId, styleId: $styleId, commentId: $commentId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.DeleteCommentDocument,
    "\n    mutation CreateCommentLike($styleId: ID!, $userId: ID!, $commentId: ID!, $input: CreateCommentLikeInput!) {\n      createCommentLike(styleId: $styleId, userId: $userId, commentId: $commentId, input: $input) {\n        data {\n          ...FragmentCommentLike\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.CreateCommentLikeDocument,
    "\n    mutation DeleteCommentLike($styleId: ID!, $userId: ID!, $commentId: ID!) {\n      deleteCommentLike(styleId: $styleId, userId: $userId, commentId: $commentId) {\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.DeleteCommentLikeDocument,
    "\n    mutation CreateFeatureRequest($userId: ID!, $input: CreateFeatureRequestInput!) {\n      createFeatureRequest(userId: $userId, input: $input) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.CreateFeatureRequestDocument,
    "\n    mutation UpdateFeatureRequestAsRequested($userId: ID!, $featureRequestId: ID!, $input: UpdateFeatureRequestAsRequestedInput!) {\n      updateFeatureRequestAsRequested(userId: $userId, featureRequestId: $featureRequestId, input: $input) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.UpdateFeatureRequestAsRequestedDocument,
    "\n    mutation UpdateFeatureRequestAsApproved($userId: ID!, $featureRequestId: ID!, $input: UpdateFeatureRequestAsApprovedInput!) {\n      updateFeatureRequestAsApproved(userId: $userId, featureRequestId: $featureRequestId, input: $input) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.UpdateFeatureRequestAsApprovedDocument,
    "\n    mutation UpdateFeatureRequestAsRejected($userId: ID!, $featureRequestId: ID!, $input: UpdateFeatureRequestAsRejectedInput!) {\n      updateFeatureRequestAsRejected(userId: $userId, featureRequestId: $featureRequestId, input: $input) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.UpdateFeatureRequestAsRejectedDocument,
    "\n    mutation UpdateFeatureRequestForChoice($userId: ID!, $featureRequestId: ID!, $choosingUserId: ID!, $isLike: Boolean!) {\n      updateFeatureRequestForChoice(userId: $userId, featureRequestId: $featureRequestId, choosingUserId: $choosingUserId, isLike: $isLike) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.UpdateFeatureRequestForChoiceDocument,
    "\n    mutation DeleteFeatureRequest($userId: ID!, $featureRequestId: ID!) {\n      deleteFeatureRequest(userId: $userId, featureRequestId: $featureRequestId) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.DeleteFeatureRequestDocument,
    "\n    mutation InitiateUpload($userId: ID!, $input: InitiateUploadInput!) {\n      initiateUpload(userId: $userId, input: $input) {\n        data {\n          uploadUrl\n          uploadPostFields\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.InitiateUploadDocument,
    "\n    mutation FinishUpload($userId: ID!, $input: FinishUploadInput!) {\n      finishUpload(userId: $userId, input: $input) {\n        data {\n          originalS3Key\n          newS3Key\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  ": types.FinishUploadDocument,
    "\n    fragment FragmentError on Error {\n      name\n      message\n      path\n    }\n  ": types.FragmentErrorFragmentDoc,
    "\n    fragment FragmentUser on User {\n      userId\n      preferredUsername\n      pendingRequiredFields\n      userType\n      firstName\n      lastName\n      phone\n      email\n      instagramUsername\n      bookingLink\n      profilePicture {\n        s3Key\n      }\n      latitude\n      longitude\n      geohashCoarse\n      geohash\n      gender\n      ethnicity\n      birthdate {\n        month\n        day\n        year\n      }\n      referredBy\n      referredTo\n      referredToEmail\n      numStyle\n      numStylePicture\n      numStyleChoice\n      numLike\n      numDislike\n      numSave\n      latestStyleChoiceTime\n      timeCreated\n      timeDeleted\n    }\n  ": types.FragmentUserFragmentDoc,
    "\n    fragment FragmentUserPublic on UserPublic {\n      userId\n      preferredUsername\n      userType\n      firstName\n      instagramUsername\n      bookingLink\n      profilePicture {\n        s3Key\n      }\n      numStyle\n      numStylePicture\n      timeCreated\n      timeDeleted\n    }\n  ": types.FragmentUserPublicFragmentDoc,
    "\n    fragment FragmentStyle on Style {\n      userId\n      styleId\n      stylistUserId\n      serviceType\n      serviceTypes\n      gender\n      ethnicity\n      approvalState\n      approvalStateRejectedReason\n      styleFeatures\n      stylePictures {\n        s3Key\n      }\n      description\n      requestedStylePictures {\n        s3Key\n      }\n      requestedDescription\n      timeApprovalStateUpdated\n      timeRequested\n      timeApproved\n      timeRejected\n      timeDeleted\n      numStyleChoice\n      numLike\n      numDislike\n      numSave\n      latestStyleChoiceTime\n      numComment\n      latestCommentTime\n      geohash\n      geohashCoarse\n    }\n  ": types.FragmentStyleFragmentDoc,
    "\n    fragment FragmentStylePublic on StylePublic {\n      userId\n      styleId\n      stylistUserId\n      serviceType\n      serviceTypes\n      gender\n      ethnicity\n      approvalState\n\n      stylePictures {\n        s3Key\n      }\n      description\n      timeApprovalStateUpdated\n      timeRequested\n      timeApproved\n      timeDeleted\n      numStyleChoice\n      numLike\n      numDislike\n      numSave\n      latestStyleChoiceTime\n      numComment\n      latestCommentTime\n      geohashCoarse\n    }\n  ": types.FragmentStylePublicFragmentDoc,
    "\n    fragment FragmentStyleChoice on StyleChoice {\n      userId\n      styleId\n      styleUserId\n      stylistUserId\n      serviceType\n      serviceTypes\n      choiceState\n      timeChoiceStateUpdated\n      timeQueued\n      timeChosen\n      timeDeleted\n      geohashCoarse\n    }\n  ": types.FragmentStyleChoiceFragmentDoc,
    "\n    fragment FragmentRecommendation on Recommendation {\n      userId\n      styleId\n      styleUserId\n      serviceType\n      serviceTypes\n      timeGenerated\n      score\n      geohash\n      geohashCoarse\n    }\n  ": types.FragmentRecommendationFragmentDoc,
    "\n    fragment FragmentComment on Comment {\n      userId\n      styleId\n      styleUserId\n      commentId\n      rootCommentId\n      rootCommentUserId\n      parentCommentId\n      parentCommentUserId\n      text\n      requestedText\n      numLike\n      numReply\n      approvalState\n      approvalStateRejectedReason\n      timeApprovalStateUpdated\n      timeRequested\n      timeApproved\n      timeRejected\n      timeDeleted\n    }\n  ": types.FragmentCommentFragmentDoc,
    "\n    fragment FragmentCommentPublic on CommentPublic {\n      userId\n      styleId\n      styleUserId\n      commentId\n      rootCommentId\n      rootCommentUserId\n      parentCommentId\n      parentCommentUserId\n      text\n      numLike\n      numReply\n      approvalState\n      timeApprovalStateUpdated\n      timeRequested\n      timeApproved\n      timeDeleted\n    }\n  ": types.FragmentCommentPublicFragmentDoc,
    "\n    fragment FragmentCommentLike on CommentLike {\n      commentId\n      styleId\n      styleUserId\n      userId\n      commentUserId\n      timeLiked\n    }\n  ": types.FragmentCommentLikeFragmentDoc,
    "\n    fragment FragmentFeatureRequest on FeatureRequest {\n      userId\n      featureRequestId\n      title\n      description\n      requestedTitle\n      requestedDescription\n      numChoice\n      numLike\n      numDislike\n      userIdsLiked\n      userIdsDisliked\n      approvalState\n      approvalStateRejectedReason\n      timeApprovalStateUpdated\n      timeRequested\n      timeApproved\n      timeRejected\n      timeDeleted\n    }\n  ": types.FragmentFeatureRequestFragmentDoc,
    "\n    fragment FragmentFeatureRequestPublic on FeatureRequestPublic {\n      userId\n      featureRequestId\n      title\n      description\n      numChoice\n      numLike\n      numDislike\n      approvalState\n      timeApprovalStateUpdated\n      timeRequested\n      timeApproved\n      timeDeleted\n    }\n  ": types.FragmentFeatureRequestPublicFragmentDoc,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query User($userId: ID!) {\n      user(userId: $userId) {\n        data {\n          ...FragmentUser\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query User($userId: ID!) {\n      user(userId: $userId) {\n        data {\n          ...FragmentUser\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query UserPublic($userId: ID!) {\n      userPublic(userId: $userId) {\n        data {\n          ...FragmentUserPublic\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query UserPublic($userId: ID!) {\n      userPublic(userId: $userId) {\n        data {\n          ...FragmentUserPublic\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query UserPublicByPreferredUsername($preferredUsername: ID!) {\n      userPublicByPreferredUsername(preferredUsername: $preferredUsername) {\n        data {\n          ...FragmentUserPublic\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query UserPublicByPreferredUsername($preferredUsername: ID!) {\n      userPublicByPreferredUsername(preferredUsername: $preferredUsername) {\n        data {\n          ...FragmentUserPublic\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query Style($userId: ID!, $styleId: ID!) {\n      style(userId: $userId, styleId: $styleId) {\n        data {\n          ...FragmentStyle\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query Style($userId: ID!, $styleId: ID!) {\n      style(userId: $userId, styleId: $styleId) {\n        data {\n          ...FragmentStyle\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query StylePublic($userId: ID!, $styleId: ID!) {\n      stylePublic(userId: $userId, styleId: $styleId) {\n        data {\n          ...FragmentStylePublic\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query StylePublic($userId: ID!, $styleId: ID!) {\n      stylePublic(userId: $userId, styleId: $styleId) {\n        data {\n          ...FragmentStylePublic\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query StylesByUser($userId: ID!, $input: StylesByUserInput!) {\n      stylesByUser(userId: $userId, input: $input) {\n        data {\n          ...FragmentStyle\n        }\n        exclusiveStartKey {\n          userId\n          timeRequested\n          styleId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query StylesByUser($userId: ID!, $input: StylesByUserInput!) {\n      stylesByUser(userId: $userId, input: $input) {\n        data {\n          ...FragmentStyle\n        }\n        exclusiveStartKey {\n          userId\n          timeRequested\n          styleId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query StylesPublicByUser($userId: ID!, $input: StylesPublicByUserInput!) {\n      stylesPublicByUser(userId: $userId, input: $input) {\n        data {\n          ...FragmentStylePublic\n        }\n        exclusiveStartKey {\n          userId\n          timeRequested\n          styleId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query StylesPublicByUser($userId: ID!, $input: StylesPublicByUserInput!) {\n      stylesPublicByUser(userId: $userId, input: $input) {\n        data {\n          ...FragmentStylePublic\n        }\n        exclusiveStartKey {\n          userId\n          timeRequested\n          styleId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query StylesByApprovalState($approvalState: ApprovalState!, $input: StylesByApprovalStateInput!) {\n      stylesByApprovalState(approvalState: $approvalState, input: $input) {\n        data {\n          ...FragmentStyle\n        }\n        exclusiveStartKey {\n          approvalState\n          timeApprovalStateUpdated\n          userId\n          styleId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query StylesByApprovalState($approvalState: ApprovalState!, $input: StylesByApprovalStateInput!) {\n      stylesByApprovalState(approvalState: $approvalState, input: $input) {\n        data {\n          ...FragmentStyle\n        }\n        exclusiveStartKey {\n          approvalState\n          timeApprovalStateUpdated\n          userId\n          styleId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query StyleFeaturesScoringTemplates {\n      styleFeaturesScoringTemplates {\n        data\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query StyleFeaturesScoringTemplates {\n      styleFeaturesScoringTemplates {\n        data\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query StyleChoice($userId: ID!, $styleId: ID!) {\n      styleChoice(userId: $userId, styleId: $styleId) {\n        data {\n          ...FragmentStyleChoice\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query StyleChoice($userId: ID!, $styleId: ID!) {\n      styleChoice(userId: $userId, styleId: $styleId) {\n        data {\n          ...FragmentStyleChoice\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query StyleChoicesByUserIdAndChoiceState(\n      $userId: ID!\n      $choiceState: ChoiceState!\n      $input: StyleChoicesByUserIdAndChoiceStateInput!\n    ) {\n      styleChoicesByUserIdAndChoiceState(userId: $userId, choiceState: $choiceState, input: $input) {\n        data {\n          ...FragmentStyleChoice\n        }\n        exclusiveStartKey {\n          userId\n          choiceState\n          timeChoiceStateUpdated\n          styleId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query StyleChoicesByUserIdAndChoiceState(\n      $userId: ID!\n      $choiceState: ChoiceState!\n      $input: StyleChoicesByUserIdAndChoiceStateInput!\n    ) {\n      styleChoicesByUserIdAndChoiceState(userId: $userId, choiceState: $choiceState, input: $input) {\n        data {\n          ...FragmentStyleChoice\n        }\n        exclusiveStartKey {\n          userId\n          choiceState\n          timeChoiceStateUpdated\n          styleId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query StyleChoicesByUserIdAndServiceTypeAndChoiceState(\n      $userId: ID!\n      $serviceType: ServiceType!\n      $choiceState: ChoiceState!\n      $input: StyleChoicesByUserIdAndServiceTypeAndChoiceStateInput!\n    ) {\n      styleChoicesByUserIdAndServiceTypeAndChoiceState(\n        userId: $userId\n        serviceType: $serviceType\n        choiceState: $choiceState\n        input: $input\n      ) {\n        data {\n          ...FragmentStyleChoice\n        }\n        exclusiveStartKey {\n          userId\n          serviceType\n          choiceState\n          timeChoiceStateUpdated\n          styleId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query StyleChoicesByUserIdAndServiceTypeAndChoiceState(\n      $userId: ID!\n      $serviceType: ServiceType!\n      $choiceState: ChoiceState!\n      $input: StyleChoicesByUserIdAndServiceTypeAndChoiceStateInput!\n    ) {\n      styleChoicesByUserIdAndServiceTypeAndChoiceState(\n        userId: $userId\n        serviceType: $serviceType\n        choiceState: $choiceState\n        input: $input\n      ) {\n        data {\n          ...FragmentStyleChoice\n        }\n        exclusiveStartKey {\n          userId\n          serviceType\n          choiceState\n          timeChoiceStateUpdated\n          styleId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query Recommendation($userId: ID!, $styleId: ID!) {\n      recommendation(userId: $userId, styleId: $styleId) {\n        data {\n          ...FragmentRecommendation\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query Recommendation($userId: ID!, $styleId: ID!) {\n      recommendation(userId: $userId, styleId: $styleId) {\n        data {\n          ...FragmentRecommendation\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query RecommendationsByUser($userId: ID!, $input: RecommendationsByUserInput!) {\n      recommendationsByUser(userId: $userId, input: $input) {\n        data {\n          ...FragmentRecommendation\n        }\n        exclusiveStartKey {\n          userId\n          timeGenerated\n          styleId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query RecommendationsByUser($userId: ID!, $input: RecommendationsByUserInput!) {\n      recommendationsByUser(userId: $userId, input: $input) {\n        data {\n          ...FragmentRecommendation\n        }\n        exclusiveStartKey {\n          userId\n          timeGenerated\n          styleId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query Comment($userId: ID!, $styleId: ID!, $commentId: ID!) {\n      comment(userId: $userId, styleId: $styleId, commentId: $commentId) {\n        data {\n          ...FragmentComment\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query Comment($userId: ID!, $styleId: ID!, $commentId: ID!) {\n      comment(userId: $userId, styleId: $styleId, commentId: $commentId) {\n        data {\n          ...FragmentComment\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query CommentPublic($userId: ID!, $styleId: ID!, $commentId: ID!) {\n      commentPublic(userId: $userId, styleId: $styleId, commentId: $commentId) {\n        data {\n          ...FragmentCommentPublic\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query CommentPublic($userId: ID!, $styleId: ID!, $commentId: ID!) {\n      commentPublic(userId: $userId, styleId: $styleId, commentId: $commentId) {\n        data {\n          ...FragmentCommentPublic\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query CommentsByUserAndStyle($userId: ID!, $styleId: ID!, $input: CommentsByUserAndStyleInput!) {\n      commentsByUserAndStyle(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        exclusiveStartKey {\n          userId\n          styleId\n          commentId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query CommentsByUserAndStyle($userId: ID!, $styleId: ID!, $input: CommentsByUserAndStyleInput!) {\n      commentsByUserAndStyle(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        exclusiveStartKey {\n          userId\n          styleId\n          commentId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query CommentsByApprovalStateAndStyle($approvalState: ApprovalState!, $styleId: ID!, $input: CommentsByApprovalStateAndStyleInput!) {\n      commentsByApprovalStateAndStyle(approvalState: $approvalState, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        exclusiveStartKey {\n          approvalState\n          styleId\n          timeRequested\n          userId\n          commentId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query CommentsByApprovalStateAndStyle($approvalState: ApprovalState!, $styleId: ID!, $input: CommentsByApprovalStateAndStyleInput!) {\n      commentsByApprovalStateAndStyle(approvalState: $approvalState, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        exclusiveStartKey {\n          approvalState\n          styleId\n          timeRequested\n          userId\n          commentId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query CommentsPublicByApprovalStateAndStyle(\n      $approvalState: ApprovalState!\n      $styleId: ID!\n      $input: CommentsPublicByApprovalStateAndStyleInput!\n    ) {\n      commentsPublicByApprovalStateAndStyle(approvalState: $approvalState, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentCommentPublic\n        }\n        exclusiveStartKey {\n          userId\n          styleId\n          commentId\n          approvalState\n          timeRequested\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query CommentsPublicByApprovalStateAndStyle(\n      $approvalState: ApprovalState!\n      $styleId: ID!\n      $input: CommentsPublicByApprovalStateAndStyleInput!\n    ) {\n      commentsPublicByApprovalStateAndStyle(approvalState: $approvalState, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentCommentPublic\n        }\n        exclusiveStartKey {\n          userId\n          styleId\n          commentId\n          approvalState\n          timeRequested\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query CommentsByApprovalState($approvalState: ApprovalState!, $input: CommentsByApprovalStateInput!) {\n      commentsByApprovalState(approvalState: $approvalState, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        exclusiveStartKey {\n          approvalState\n          timeApprovalStateUpdated\n          styleId\n          userId\n          commentId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query CommentsByApprovalState($approvalState: ApprovalState!, $input: CommentsByApprovalStateInput!) {\n      commentsByApprovalState(approvalState: $approvalState, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        exclusiveStartKey {\n          approvalState\n          timeApprovalStateUpdated\n          styleId\n          userId\n          commentId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query RootCommentsByApprovalStateAndStyle(\n      $approvalState: ApprovalState!\n      $styleId: ID!\n      $input: RootCommentsByApprovalStateAndStyleInput!\n    ) {\n      rootCommentsByApprovalStateAndStyle(approvalState: $approvalState, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        exclusiveStartKey {\n          userId\n          styleId\n          commentId\n          approvalState\n          timeRequested\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query RootCommentsByApprovalStateAndStyle(\n      $approvalState: ApprovalState!\n      $styleId: ID!\n      $input: RootCommentsByApprovalStateAndStyleInput!\n    ) {\n      rootCommentsByApprovalStateAndStyle(approvalState: $approvalState, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        exclusiveStartKey {\n          userId\n          styleId\n          commentId\n          approvalState\n          timeRequested\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query RootCommentsPublicByApprovalStateAndStyle(\n      $approvalState: ApprovalState!\n      $styleId: ID!\n      $input: RootCommentsPublicByApprovalStateAndStyleInput!\n    ) {\n      rootCommentsPublicByApprovalStateAndStyle(approvalState: $approvalState, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentCommentPublic\n        }\n        exclusiveStartKey {\n          userId\n          styleId\n          commentId\n          approvalState\n          timeRequested\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query RootCommentsPublicByApprovalStateAndStyle(\n      $approvalState: ApprovalState!\n      $styleId: ID!\n      $input: RootCommentsPublicByApprovalStateAndStyleInput!\n    ) {\n      rootCommentsPublicByApprovalStateAndStyle(approvalState: $approvalState, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentCommentPublic\n        }\n        exclusiveStartKey {\n          userId\n          styleId\n          commentId\n          approvalState\n          timeRequested\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query ChildCommentsByApprovalStateAndStyleAndRootComment(\n      $approvalState: ApprovalState!\n      $styleId: ID!\n      $rootCommentId: ID!\n      $input: ChildCommentsByApprovalStateAndStyleAndRootCommentInput!\n    ) {\n      childCommentsByApprovalStateAndStyleAndRootComment(\n        approvalState: $approvalState\n        styleId: $styleId\n        rootCommentId: $rootCommentId\n        input: $input\n      ) {\n        data {\n          ...FragmentComment\n        }\n        exclusiveStartKey {\n          userId\n          styleId\n          commentId\n          approvalState\n          rootCommentId\n          timeRequested\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query ChildCommentsByApprovalStateAndStyleAndRootComment(\n      $approvalState: ApprovalState!\n      $styleId: ID!\n      $rootCommentId: ID!\n      $input: ChildCommentsByApprovalStateAndStyleAndRootCommentInput!\n    ) {\n      childCommentsByApprovalStateAndStyleAndRootComment(\n        approvalState: $approvalState\n        styleId: $styleId\n        rootCommentId: $rootCommentId\n        input: $input\n      ) {\n        data {\n          ...FragmentComment\n        }\n        exclusiveStartKey {\n          userId\n          styleId\n          commentId\n          approvalState\n          rootCommentId\n          timeRequested\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query ChildCommentsPublicByApprovalStateAndStyleAndRootComment(\n      $approvalState: ApprovalState!\n      $styleId: ID!\n      $rootCommentId: ID!\n      $input: ChildCommentsPublicByApprovalStateAndStyleAndRootCommentInput!\n    ) {\n      childCommentsPublicByApprovalStateAndStyleAndRootComment(\n        approvalState: $approvalState\n        styleId: $styleId\n        rootCommentId: $rootCommentId\n        input: $input\n      ) {\n        data {\n          ...FragmentCommentPublic\n        }\n        exclusiveStartKey {\n          userId\n          styleId\n          commentId\n          approvalState\n          rootCommentId\n          timeRequested\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query ChildCommentsPublicByApprovalStateAndStyleAndRootComment(\n      $approvalState: ApprovalState!\n      $styleId: ID!\n      $rootCommentId: ID!\n      $input: ChildCommentsPublicByApprovalStateAndStyleAndRootCommentInput!\n    ) {\n      childCommentsPublicByApprovalStateAndStyleAndRootComment(\n        approvalState: $approvalState\n        styleId: $styleId\n        rootCommentId: $rootCommentId\n        input: $input\n      ) {\n        data {\n          ...FragmentCommentPublic\n        }\n        exclusiveStartKey {\n          userId\n          styleId\n          commentId\n          approvalState\n          rootCommentId\n          timeRequested\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query CommentLike($styleId: ID!, $userId: ID!, $commentId: ID!) {\n      commentLike(styleId: $styleId, userId: $userId, commentId: $commentId) {\n        data {\n          ...FragmentCommentLike\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query CommentLike($styleId: ID!, $userId: ID!, $commentId: ID!) {\n      commentLike(styleId: $styleId, userId: $userId, commentId: $commentId) {\n        data {\n          ...FragmentCommentLike\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query CommentLikesByStyleAndUser($styleId: ID!, $userId: ID!, $input: CommentLikesByStyleAndUserInput!) {\n      commentLikesByStyleAndUser(styleId: $styleId, userId: $userId, input: $input) {\n        data {\n          ...FragmentCommentLike\n        }\n        exclusiveStartKey {\n          styleId\n          userId\n          commentId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query CommentLikesByStyleAndUser($styleId: ID!, $userId: ID!, $input: CommentLikesByStyleAndUserInput!) {\n      commentLikesByStyleAndUser(styleId: $styleId, userId: $userId, input: $input) {\n        data {\n          ...FragmentCommentLike\n        }\n        exclusiveStartKey {\n          styleId\n          userId\n          commentId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query FeatureRequest($userId: ID!, $featureRequestId: ID!) {\n      featureRequest(userId: $userId, featureRequestId: $featureRequestId) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query FeatureRequest($userId: ID!, $featureRequestId: ID!) {\n      featureRequest(userId: $userId, featureRequestId: $featureRequestId) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query FeatureRequestPublic($userId: ID!, $featureRequestId: ID!) {\n      featureRequestPublic(userId: $userId, featureRequestId: $featureRequestId) {\n        data {\n          ...FragmentFeatureRequestPublic\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query FeatureRequestPublic($userId: ID!, $featureRequestId: ID!) {\n      featureRequestPublic(userId: $userId, featureRequestId: $featureRequestId) {\n        data {\n          ...FragmentFeatureRequestPublic\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query FeatureRequestsByApprovalState($approvalState: ApprovalState!, $input: FeatureRequestsByApprovalStateInput!) {\n      featureRequestsByApprovalState(approvalState: $approvalState, input: $input) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        exclusiveStartKey {\n          approvalState\n          timeApprovalStateUpdated\n          userId\n          featureRequestId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query FeatureRequestsByApprovalState($approvalState: ApprovalState!, $input: FeatureRequestsByApprovalStateInput!) {\n      featureRequestsByApprovalState(approvalState: $approvalState, input: $input) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        exclusiveStartKey {\n          approvalState\n          timeApprovalStateUpdated\n          userId\n          featureRequestId\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query FeatureRequestsPublicByApprovalState(\n      $approvalState: ApprovalState!\n      $input: FeatureRequestsPublicByApprovalStateInput!\n    ) {\n      featureRequestsPublicByApprovalState(approvalState: $approvalState, input: $input) {\n        data {\n          ...FragmentFeatureRequestPublic\n        }\n        exclusiveStartKey {\n          userId\n          featureRequestId\n          approvalState\n          timeApprovalStateUpdated\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    query FeatureRequestsPublicByApprovalState(\n      $approvalState: ApprovalState!\n      $input: FeatureRequestsPublicByApprovalStateInput!\n    ) {\n      featureRequestsPublicByApprovalState(approvalState: $approvalState, input: $input) {\n        data {\n          ...FragmentFeatureRequestPublic\n        }\n        exclusiveStartKey {\n          userId\n          featureRequestId\n          approvalState\n          timeApprovalStateUpdated\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateUser($userId: ID!, $email: String!) {\n      createUser(userId: $userId, email: $email) {\n        data {\n          ...FragmentUser\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation CreateUser($userId: ID!, $email: String!) {\n      createUser(userId: $userId, email: $email) {\n        data {\n          ...FragmentUser\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateUser($userId: ID!, $input: UpdateUserInput!) {\n      updateUser(userId: $userId, input: $input) {\n        data {\n          ...FragmentUser\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateUser($userId: ID!, $input: UpdateUserInput!) {\n      updateUser(userId: $userId, input: $input) {\n        data {\n          ...FragmentUser\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateUserForReferring($referringUserId: ID!, $referredUserEmail: String!) {\n      updateUserForReferring(referringUserId: $referringUserId, referredUserEmail: $referredUserEmail) {\n        data {\n          ...FragmentUser\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateUserForReferring($referringUserId: ID!, $referredUserEmail: String!) {\n      updateUserForReferring(referringUserId: $referringUserId, referredUserEmail: $referredUserEmail) {\n        data {\n          ...FragmentUser\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateUserForReferred($referredUserId: ID!, $referredUserEmail: String!, $referringUserId: ID!) {\n      updateUserForReferred(\n        referredUserId: $referredUserId\n        referredUserEmail: $referredUserEmail\n        referringUserId: $referringUserId\n      ) {\n        data {\n          ...FragmentUser\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateUserForReferred($referredUserId: ID!, $referredUserEmail: String!, $referringUserId: ID!) {\n      updateUserForReferred(\n        referredUserId: $referredUserId\n        referredUserEmail: $referredUserEmail\n        referringUserId: $referringUserId\n      ) {\n        data {\n          ...FragmentUser\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateUserPreferredUsername($userId: ID!, $currentPreferredUsername: ID!, $newPreferredUsername: ID!) {\n      updateUserPreferredUsername(\n        userId: $userId\n        currentPreferredUsername: $currentPreferredUsername\n        newPreferredUsername: $newPreferredUsername\n      ) {\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateUserPreferredUsername($userId: ID!, $currentPreferredUsername: ID!, $newPreferredUsername: ID!) {\n      updateUserPreferredUsername(\n        userId: $userId\n        currentPreferredUsername: $currentPreferredUsername\n        newPreferredUsername: $newPreferredUsername\n      ) {\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateStyle($userId: ID!, $input: CreateStyleInput!) {\n      createStyle(userId: $userId, input: $input) {\n        data {\n          ...FragmentStyle\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation CreateStyle($userId: ID!, $input: CreateStyleInput!) {\n      createStyle(userId: $userId, input: $input) {\n        data {\n          ...FragmentStyle\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateStyleAsRequested($userId: ID!, $styleId: ID!, $input: UpdateStyleAsRequestedInput!) {\n      updateStyleAsRequested(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentStyle\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateStyleAsRequested($userId: ID!, $styleId: ID!, $input: UpdateStyleAsRequestedInput!) {\n      updateStyleAsRequested(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentStyle\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateStyleAsApproved($userId: ID!, $styleId: ID!, $input: UpdateStyleAsApprovedInput!) {\n      updateStyleAsApproved(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentStyle\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateStyleAsApproved($userId: ID!, $styleId: ID!, $input: UpdateStyleAsApprovedInput!) {\n      updateStyleAsApproved(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentStyle\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateStyleAsRejected($userId: ID!, $styleId: ID!, $input: UpdateStyleAsRejectedInput!) {\n      updateStyleAsRejected(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentStyle\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateStyleAsRejected($userId: ID!, $styleId: ID!, $input: UpdateStyleAsRejectedInput!) {\n      updateStyleAsRejected(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentStyle\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation DeleteStyle($userId: ID!, $styleId: ID!) {\n      deleteStyle(userId: $userId, styleId: $styleId) {\n        data {\n          ...FragmentStyle\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation DeleteStyle($userId: ID!, $styleId: ID!) {\n      deleteStyle(userId: $userId, styleId: $styleId) {\n        data {\n          ...FragmentStyle\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateStyleChoice($userId: ID!, $styleId: ID!, $input: CreateStyleChoiceInput!) {\n      createStyleChoice(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentStyleChoice\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation CreateStyleChoice($userId: ID!, $styleId: ID!, $input: CreateStyleChoiceInput!) {\n      createStyleChoice(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentStyleChoice\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateStyleChoiceForChoice(\n      $userId: ID!\n      $styleId: ID!\n      $serviceType: ServiceType!\n      $choice: ChoiceState!\n      $location: LocationInput\n    ) {\n      updateStyleChoiceForChoice(\n        userId: $userId\n        styleId: $styleId\n        serviceType: $serviceType\n        choice: $choice\n        location: $location\n      ) {\n        data {\n          ...FragmentStyleChoice\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateStyleChoiceForChoice(\n      $userId: ID!\n      $styleId: ID!\n      $serviceType: ServiceType!\n      $choice: ChoiceState!\n      $location: LocationInput\n    ) {\n      updateStyleChoiceForChoice(\n        userId: $userId\n        styleId: $styleId\n        serviceType: $serviceType\n        choice: $choice\n        location: $location\n      ) {\n        data {\n          ...FragmentStyleChoice\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateStyleChoiceForUndo($userId: ID!, $styleId: ID!, $serviceType: ServiceType!) {\n      updateStyleChoiceForUndo(userId: $userId, styleId: $styleId, serviceType: $serviceType) {\n        data {\n          ...FragmentStyleChoice\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateStyleChoiceForUndo($userId: ID!, $styleId: ID!, $serviceType: ServiceType!) {\n      updateStyleChoiceForUndo(userId: $userId, styleId: $styleId, serviceType: $serviceType) {\n        data {\n          ...FragmentStyleChoice\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation DeleteStyleChoice($userId: ID!, $styleId: ID!, $serviceType: ServiceType!) {\n      deleteStyleChoice(userId: $userId, styleId: $styleId, serviceType: $serviceType) {\n        data {\n          ...FragmentStyleChoice\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation DeleteStyleChoice($userId: ID!, $styleId: ID!, $serviceType: ServiceType!) {\n      deleteStyleChoice(userId: $userId, styleId: $styleId, serviceType: $serviceType) {\n        data {\n          ...FragmentStyleChoice\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation QueueStyleChoices($userId: ID!, $input: QueueStyleChoicesInput!) {\n      queueStyleChoices(userId: $userId, input: $input) {\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation QueueStyleChoices($userId: ID!, $input: QueueStyleChoicesInput!) {\n      queueStyleChoices(userId: $userId, input: $input) {\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateRecommendation($userId: ID!, $styleId: ID!, $input: CreateRecommendationInput!) {\n      createRecommendation(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentRecommendation\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation CreateRecommendation($userId: ID!, $styleId: ID!, $input: CreateRecommendationInput!) {\n      createRecommendation(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentRecommendation\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateComment($userId: ID!, $styleId: ID!, $input: CreateCommentInput!) {\n      createComment(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation CreateComment($userId: ID!, $styleId: ID!, $input: CreateCommentInput!) {\n      createComment(userId: $userId, styleId: $styleId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateCommentAsRequested($userId: ID!, $styleId: ID!, $commentId: ID!, $input: UpdateCommentAsRequestedInput!) {\n      updateCommentAsRequested(userId: $userId, styleId: $styleId, commentId: $commentId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateCommentAsRequested($userId: ID!, $styleId: ID!, $commentId: ID!, $input: UpdateCommentAsRequestedInput!) {\n      updateCommentAsRequested(userId: $userId, styleId: $styleId, commentId: $commentId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateCommentAsApproved($userId: ID!, $styleId: ID!, $commentId: ID!, $input: UpdateCommentAsApprovedInput!) {\n      updateCommentAsApproved(userId: $userId, styleId: $styleId, commentId: $commentId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateCommentAsApproved($userId: ID!, $styleId: ID!, $commentId: ID!, $input: UpdateCommentAsApprovedInput!) {\n      updateCommentAsApproved(userId: $userId, styleId: $styleId, commentId: $commentId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateCommentAsRejected($userId: ID!, $styleId: ID!, $commentId: ID!, $input: UpdateCommentAsRejectedInput!) {\n      updateCommentAsRejected(userId: $userId, styleId: $styleId, commentId: $commentId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateCommentAsRejected($userId: ID!, $styleId: ID!, $commentId: ID!, $input: UpdateCommentAsRejectedInput!) {\n      updateCommentAsRejected(userId: $userId, styleId: $styleId, commentId: $commentId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation DeleteComment($userId: ID!, $styleId: ID!, $commentId: ID!, $input: DeleteCommentInput!) {\n      deleteComment(userId: $userId, styleId: $styleId, commentId: $commentId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation DeleteComment($userId: ID!, $styleId: ID!, $commentId: ID!, $input: DeleteCommentInput!) {\n      deleteComment(userId: $userId, styleId: $styleId, commentId: $commentId, input: $input) {\n        data {\n          ...FragmentComment\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateCommentLike($styleId: ID!, $userId: ID!, $commentId: ID!, $input: CreateCommentLikeInput!) {\n      createCommentLike(styleId: $styleId, userId: $userId, commentId: $commentId, input: $input) {\n        data {\n          ...FragmentCommentLike\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation CreateCommentLike($styleId: ID!, $userId: ID!, $commentId: ID!, $input: CreateCommentLikeInput!) {\n      createCommentLike(styleId: $styleId, userId: $userId, commentId: $commentId, input: $input) {\n        data {\n          ...FragmentCommentLike\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation DeleteCommentLike($styleId: ID!, $userId: ID!, $commentId: ID!) {\n      deleteCommentLike(styleId: $styleId, userId: $userId, commentId: $commentId) {\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation DeleteCommentLike($styleId: ID!, $userId: ID!, $commentId: ID!) {\n      deleteCommentLike(styleId: $styleId, userId: $userId, commentId: $commentId) {\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateFeatureRequest($userId: ID!, $input: CreateFeatureRequestInput!) {\n      createFeatureRequest(userId: $userId, input: $input) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation CreateFeatureRequest($userId: ID!, $input: CreateFeatureRequestInput!) {\n      createFeatureRequest(userId: $userId, input: $input) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateFeatureRequestAsRequested($userId: ID!, $featureRequestId: ID!, $input: UpdateFeatureRequestAsRequestedInput!) {\n      updateFeatureRequestAsRequested(userId: $userId, featureRequestId: $featureRequestId, input: $input) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateFeatureRequestAsRequested($userId: ID!, $featureRequestId: ID!, $input: UpdateFeatureRequestAsRequestedInput!) {\n      updateFeatureRequestAsRequested(userId: $userId, featureRequestId: $featureRequestId, input: $input) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateFeatureRequestAsApproved($userId: ID!, $featureRequestId: ID!, $input: UpdateFeatureRequestAsApprovedInput!) {\n      updateFeatureRequestAsApproved(userId: $userId, featureRequestId: $featureRequestId, input: $input) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateFeatureRequestAsApproved($userId: ID!, $featureRequestId: ID!, $input: UpdateFeatureRequestAsApprovedInput!) {\n      updateFeatureRequestAsApproved(userId: $userId, featureRequestId: $featureRequestId, input: $input) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateFeatureRequestAsRejected($userId: ID!, $featureRequestId: ID!, $input: UpdateFeatureRequestAsRejectedInput!) {\n      updateFeatureRequestAsRejected(userId: $userId, featureRequestId: $featureRequestId, input: $input) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateFeatureRequestAsRejected($userId: ID!, $featureRequestId: ID!, $input: UpdateFeatureRequestAsRejectedInput!) {\n      updateFeatureRequestAsRejected(userId: $userId, featureRequestId: $featureRequestId, input: $input) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpdateFeatureRequestForChoice($userId: ID!, $featureRequestId: ID!, $choosingUserId: ID!, $isLike: Boolean!) {\n      updateFeatureRequestForChoice(userId: $userId, featureRequestId: $featureRequestId, choosingUserId: $choosingUserId, isLike: $isLike) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateFeatureRequestForChoice($userId: ID!, $featureRequestId: ID!, $choosingUserId: ID!, $isLike: Boolean!) {\n      updateFeatureRequestForChoice(userId: $userId, featureRequestId: $featureRequestId, choosingUserId: $choosingUserId, isLike: $isLike) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation DeleteFeatureRequest($userId: ID!, $featureRequestId: ID!) {\n      deleteFeatureRequest(userId: $userId, featureRequestId: $featureRequestId) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation DeleteFeatureRequest($userId: ID!, $featureRequestId: ID!) {\n      deleteFeatureRequest(userId: $userId, featureRequestId: $featureRequestId) {\n        data {\n          ...FragmentFeatureRequest\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation InitiateUpload($userId: ID!, $input: InitiateUploadInput!) {\n      initiateUpload(userId: $userId, input: $input) {\n        data {\n          uploadUrl\n          uploadPostFields\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation InitiateUpload($userId: ID!, $input: InitiateUploadInput!) {\n      initiateUpload(userId: $userId, input: $input) {\n        data {\n          uploadUrl\n          uploadPostFields\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation FinishUpload($userId: ID!, $input: FinishUploadInput!) {\n      finishUpload(userId: $userId, input: $input) {\n        data {\n          originalS3Key\n          newS3Key\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation FinishUpload($userId: ID!, $input: FinishUploadInput!) {\n      finishUpload(userId: $userId, input: $input) {\n        data {\n          originalS3Key\n          newS3Key\n        }\n        errors {\n          ...FragmentError\n        }\n      }\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment FragmentError on Error {\n      name\n      message\n      path\n    }\n  "): (typeof documents)["\n    fragment FragmentError on Error {\n      name\n      message\n      path\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment FragmentUser on User {\n      userId\n      preferredUsername\n      pendingRequiredFields\n      userType\n      firstName\n      lastName\n      phone\n      email\n      instagramUsername\n      bookingLink\n      profilePicture {\n        s3Key\n      }\n      latitude\n      longitude\n      geohashCoarse\n      geohash\n      gender\n      ethnicity\n      birthdate {\n        month\n        day\n        year\n      }\n      referredBy\n      referredTo\n      referredToEmail\n      numStyle\n      numStylePicture\n      numStyleChoice\n      numLike\n      numDislike\n      numSave\n      latestStyleChoiceTime\n      timeCreated\n      timeDeleted\n    }\n  "): (typeof documents)["\n    fragment FragmentUser on User {\n      userId\n      preferredUsername\n      pendingRequiredFields\n      userType\n      firstName\n      lastName\n      phone\n      email\n      instagramUsername\n      bookingLink\n      profilePicture {\n        s3Key\n      }\n      latitude\n      longitude\n      geohashCoarse\n      geohash\n      gender\n      ethnicity\n      birthdate {\n        month\n        day\n        year\n      }\n      referredBy\n      referredTo\n      referredToEmail\n      numStyle\n      numStylePicture\n      numStyleChoice\n      numLike\n      numDislike\n      numSave\n      latestStyleChoiceTime\n      timeCreated\n      timeDeleted\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment FragmentUserPublic on UserPublic {\n      userId\n      preferredUsername\n      userType\n      firstName\n      instagramUsername\n      bookingLink\n      profilePicture {\n        s3Key\n      }\n      numStyle\n      numStylePicture\n      timeCreated\n      timeDeleted\n    }\n  "): (typeof documents)["\n    fragment FragmentUserPublic on UserPublic {\n      userId\n      preferredUsername\n      userType\n      firstName\n      instagramUsername\n      bookingLink\n      profilePicture {\n        s3Key\n      }\n      numStyle\n      numStylePicture\n      timeCreated\n      timeDeleted\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment FragmentStyle on Style {\n      userId\n      styleId\n      stylistUserId\n      serviceType\n      serviceTypes\n      gender\n      ethnicity\n      approvalState\n      approvalStateRejectedReason\n      styleFeatures\n      stylePictures {\n        s3Key\n      }\n      description\n      requestedStylePictures {\n        s3Key\n      }\n      requestedDescription\n      timeApprovalStateUpdated\n      timeRequested\n      timeApproved\n      timeRejected\n      timeDeleted\n      numStyleChoice\n      numLike\n      numDislike\n      numSave\n      latestStyleChoiceTime\n      numComment\n      latestCommentTime\n      geohash\n      geohashCoarse\n    }\n  "): (typeof documents)["\n    fragment FragmentStyle on Style {\n      userId\n      styleId\n      stylistUserId\n      serviceType\n      serviceTypes\n      gender\n      ethnicity\n      approvalState\n      approvalStateRejectedReason\n      styleFeatures\n      stylePictures {\n        s3Key\n      }\n      description\n      requestedStylePictures {\n        s3Key\n      }\n      requestedDescription\n      timeApprovalStateUpdated\n      timeRequested\n      timeApproved\n      timeRejected\n      timeDeleted\n      numStyleChoice\n      numLike\n      numDislike\n      numSave\n      latestStyleChoiceTime\n      numComment\n      latestCommentTime\n      geohash\n      geohashCoarse\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment FragmentStylePublic on StylePublic {\n      userId\n      styleId\n      stylistUserId\n      serviceType\n      serviceTypes\n      gender\n      ethnicity\n      approvalState\n\n      stylePictures {\n        s3Key\n      }\n      description\n      timeApprovalStateUpdated\n      timeRequested\n      timeApproved\n      timeDeleted\n      numStyleChoice\n      numLike\n      numDislike\n      numSave\n      latestStyleChoiceTime\n      numComment\n      latestCommentTime\n      geohashCoarse\n    }\n  "): (typeof documents)["\n    fragment FragmentStylePublic on StylePublic {\n      userId\n      styleId\n      stylistUserId\n      serviceType\n      serviceTypes\n      gender\n      ethnicity\n      approvalState\n\n      stylePictures {\n        s3Key\n      }\n      description\n      timeApprovalStateUpdated\n      timeRequested\n      timeApproved\n      timeDeleted\n      numStyleChoice\n      numLike\n      numDislike\n      numSave\n      latestStyleChoiceTime\n      numComment\n      latestCommentTime\n      geohashCoarse\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment FragmentStyleChoice on StyleChoice {\n      userId\n      styleId\n      styleUserId\n      stylistUserId\n      serviceType\n      serviceTypes\n      choiceState\n      timeChoiceStateUpdated\n      timeQueued\n      timeChosen\n      timeDeleted\n      geohashCoarse\n    }\n  "): (typeof documents)["\n    fragment FragmentStyleChoice on StyleChoice {\n      userId\n      styleId\n      styleUserId\n      stylistUserId\n      serviceType\n      serviceTypes\n      choiceState\n      timeChoiceStateUpdated\n      timeQueued\n      timeChosen\n      timeDeleted\n      geohashCoarse\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment FragmentRecommendation on Recommendation {\n      userId\n      styleId\n      styleUserId\n      serviceType\n      serviceTypes\n      timeGenerated\n      score\n      geohash\n      geohashCoarse\n    }\n  "): (typeof documents)["\n    fragment FragmentRecommendation on Recommendation {\n      userId\n      styleId\n      styleUserId\n      serviceType\n      serviceTypes\n      timeGenerated\n      score\n      geohash\n      geohashCoarse\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment FragmentComment on Comment {\n      userId\n      styleId\n      styleUserId\n      commentId\n      rootCommentId\n      rootCommentUserId\n      parentCommentId\n      parentCommentUserId\n      text\n      requestedText\n      numLike\n      numReply\n      approvalState\n      approvalStateRejectedReason\n      timeApprovalStateUpdated\n      timeRequested\n      timeApproved\n      timeRejected\n      timeDeleted\n    }\n  "): (typeof documents)["\n    fragment FragmentComment on Comment {\n      userId\n      styleId\n      styleUserId\n      commentId\n      rootCommentId\n      rootCommentUserId\n      parentCommentId\n      parentCommentUserId\n      text\n      requestedText\n      numLike\n      numReply\n      approvalState\n      approvalStateRejectedReason\n      timeApprovalStateUpdated\n      timeRequested\n      timeApproved\n      timeRejected\n      timeDeleted\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment FragmentCommentPublic on CommentPublic {\n      userId\n      styleId\n      styleUserId\n      commentId\n      rootCommentId\n      rootCommentUserId\n      parentCommentId\n      parentCommentUserId\n      text\n      numLike\n      numReply\n      approvalState\n      timeApprovalStateUpdated\n      timeRequested\n      timeApproved\n      timeDeleted\n    }\n  "): (typeof documents)["\n    fragment FragmentCommentPublic on CommentPublic {\n      userId\n      styleId\n      styleUserId\n      commentId\n      rootCommentId\n      rootCommentUserId\n      parentCommentId\n      parentCommentUserId\n      text\n      numLike\n      numReply\n      approvalState\n      timeApprovalStateUpdated\n      timeRequested\n      timeApproved\n      timeDeleted\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment FragmentCommentLike on CommentLike {\n      commentId\n      styleId\n      styleUserId\n      userId\n      commentUserId\n      timeLiked\n    }\n  "): (typeof documents)["\n    fragment FragmentCommentLike on CommentLike {\n      commentId\n      styleId\n      styleUserId\n      userId\n      commentUserId\n      timeLiked\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment FragmentFeatureRequest on FeatureRequest {\n      userId\n      featureRequestId\n      title\n      description\n      requestedTitle\n      requestedDescription\n      numChoice\n      numLike\n      numDislike\n      userIdsLiked\n      userIdsDisliked\n      approvalState\n      approvalStateRejectedReason\n      timeApprovalStateUpdated\n      timeRequested\n      timeApproved\n      timeRejected\n      timeDeleted\n    }\n  "): (typeof documents)["\n    fragment FragmentFeatureRequest on FeatureRequest {\n      userId\n      featureRequestId\n      title\n      description\n      requestedTitle\n      requestedDescription\n      numChoice\n      numLike\n      numDislike\n      userIdsLiked\n      userIdsDisliked\n      approvalState\n      approvalStateRejectedReason\n      timeApprovalStateUpdated\n      timeRequested\n      timeApproved\n      timeRejected\n      timeDeleted\n    }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    fragment FragmentFeatureRequestPublic on FeatureRequestPublic {\n      userId\n      featureRequestId\n      title\n      description\n      numChoice\n      numLike\n      numDislike\n      approvalState\n      timeApprovalStateUpdated\n      timeRequested\n      timeApproved\n      timeDeleted\n    }\n  "): (typeof documents)["\n    fragment FragmentFeatureRequestPublic on FeatureRequestPublic {\n      userId\n      featureRequestId\n      title\n      description\n      numChoice\n      numLike\n      numDislike\n      approvalState\n      timeApprovalStateUpdated\n      timeRequested\n      timeApproved\n      timeDeleted\n    }\n  "];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;